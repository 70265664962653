import React from "react";
import "./ProgressBar.scss";

const ProgressBar = ({
  current = 0,
  total = 1,
  title = '',
}) => {
  const totalCount = total === 0 ? 1 : total;
  return (
    <>
      <div className="progress-bar">
        <div className="progress-bar__line" style={{ width: (current * 100 / totalCount) + '%' }}></div>
        <span>{parseInt(current * 100 / totalCount)} %</span>
      </div>
    </>
  );
};

export default ProgressBar;
