import React, { useContext, useState, useEffect } from 'react';
import './RequestedJobPaying.scss';
import Button from '../../../partials/Button/Button';
import ImagePreviews from '../../ImagePreviews/ImagePreviews';
import { DispatchJobContext, StateJobContext } from '../../../../context/JobContext';
import DropDown from '../../../partials/DropDown/DropDown';
import { StateUserContext } from '../../../../context/UserContext';
import { useApi } from '../../../../hooks/useApi';
import { url } from '../../../../constants/url';
import { useShowNotification } from '../../../../hooks/notifications/useShowNotification';
import { withTranslation } from 'react-i18next';

const RequestedJobPaying = ({ className = '', t, ...attr }) => {
  const jobState = useContext(StateJobContext);

  const jobDispatch = useContext(DispatchJobContext);
  const { invoice: invoiceIs } = useContext(StateUserContext);
  const { invoice, name, job_items, urgency, uploaded_size } = jobState;
  console.log("ITEMS", job_items);
  const {
    sub_total,
    total,
    items: { complex, simple, super_complex },
    urgency_percent,
  } = invoice;
  const { infoNotification } = useShowNotification();

  //Start handle pay
  const [paymentMethodName, setPaymentMethodName] = useState(t('job.pay_online')); //should be object
  const [paymentMethodType, setPaymentMethodType] = useState('mollie'); //should be object
  const [paymentPostData, setPaymentPostData] = useState([]);
  const sendPayment = useApi(...paymentPostData);
  const handlePayType = (type, e) => {
    setPaymentMethodName(e.target.innerText);
    setPaymentMethodType(type);
  };

  const handlePayBy = () => {
    if (paymentMethodType === 'mollie') {
      setPaymentPostData([
        `${url}payments/invoice/${invoice.id}/pay-by/${paymentMethodType}`,
        {
          method: 'POST',
        },
      ]);
    } else {
      setPaymentPostData([
        `${url}payments/invoice/${invoice.id}/pay-by/${paymentMethodType}`,
        {
          method: 'POST',
        },
      ]);
    }
  };

  const { isLoading: paymentIsLoading, data: paymentData, error: paymentError } = sendPayment;

  if (paymentData !== null && !paymentError && !paymentIsLoading) {
    if (paymentMethodType === 'mollie') {
      window.location.replace(`${sendPayment.data.url}`);
    } else {
      jobDispatch({
        type: 'UPDATE-JOB-DATA',
        payload: paymentData,
      });
      infoNotification(t('notifications.info.payment_successful'));
    }
  }
  //End handle pay

  useEffect(() => {
    setPaymentMethodName(t('job.pay_online'));
  }, [t]);

  return (
    <>
      <div className="container">
        <div className={`section__content job__requested-pricing  ${className}`} {...attr}>
          <div className="job__heading">
            <h1 className="title">{t('job.job_ready_for_approval')}</h1>
            <p className="sub-title">{t('job.pricing_description')}</p>
          </div>
          <div className="job__body">
            <div className="job__detail">
              <div className="job__detail-item">
                <div>{t('job.job_name')}</div>
                <div>{name}</div>
              </div>

              <div className="job__detail-item">
                <div>{t('dashboard.pricing')}</div>
                <div className="content-line ">
                  <span>{t('job.simple_images')}</span>
                  <span>
                    <small>{simple.length} {t('job.images')}</small>
                    <strong>€{simple.length > 0 ? simple.length * simple[0].cost : 0}</strong>
                  </span>
                </div>
                <div className="content-line ">
                  <span>{t('job.complex_images')}</span>
                  <span>
                    <small>{complex.length} {t('job.images')}</small>
                    <strong>€{complex.length > 0 ? complex.length * complex[0].cost : 0}</strong>
                  </span>
                </div>
                <div className="content-line ">
                  <span>{t('job.super_complex_images')}</span>
                  <span>
                    <small>{super_complex.length} {t('job.images')}</small>
                    <strong>€{super_complex.length > 0 ? super_complex.length * super_complex[0].cost : 0}</strong>
                  </span>
                </div>
              </div>

              {urgency && (
                <div className="job__detail-item">
                  <div>{t('job.extra_changes')}</div>
                  <div className="content-line ">
                    <span>{t('job.urgency.12_hours')}</span>
                    <span>
                      {/*<small></small>*/}
                      <strong>+{urgency_percent * 100}%</strong>
                    </span>
                  </div>
                </div>
              )}

              <div className="job__detail-item">
                <div>{t('job.total')}</div>
                <div className="content-line ">
                  <span>{t('job.images')}</span>
                  <span>
                    <small>{simple.length + complex.length + super_complex.length} {t('job.images')}</small>
                    <strong>€{sub_total}</strong>
                  </span>
                </div>
                <div className="content-line ">
                  <span>{t('job.vat')}</span>
                  <span>
                    <strong>€{(total - sub_total).toFixed(2)}</strong>
                  </span>
                </div>

                <hr />
                <div className="content-line ">
                  <span>{t('job.total')}</span>
                  <span>
                    <strong>€{parseFloat(total).toFixed(2)}</strong>
                  </span>
                </div>
              </div>

              <div className="btn-group btn-group--payment">
                <Button className="btn--yellow btn--big" onClick={handlePayBy}>
                  {paymentIsLoading || paymentData ? t('job.paying') : paymentMethodName}
                </Button>
                { invoiceIs && (
                  <DropDown select={true} className="drop-down--select" label={<Button className="btn--yellow arrow arrow--bottom btn--big"></Button>}>
                    <ul className="drop-down__select-list">
                      <li onClick={e => handlePayType('mollie', e)}>{t('job.pay_online')}</li>
                      {invoiceIs && <li onClick={e => handlePayType('invoice', e)}>{t('profile.form.pay_by_invoice_label')}</li>}
                      {/*<li onClick={handlePayType}>Use your credit</li>*/}
                    </ul>
                  </DropDown>
                )}
              </div>
            </div>
            <div className="job__images">
              <div className="job__detail-item">
                <div className="content-line ">
                  <span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.06" cx="16" cy="16" r="16" fill="#05C46B" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                        fill="#05C46B"
                      />
                    </svg>
                    {t('job.simple_images')}
                  </span>
                  <span>
                    <small>{job_items.simple.length} {t('job.images')}</small>
                  </span>
                </div>
                <ImagePreviews
                  job_items={job_items.simple}
                  maxItems={2}
                  uploadedSize={job_items.simple.reduce((accumulator, currentValue) => accumulator + Number(currentValue.size), 0)}
                  editable={false} />
              </div>

              <div className="job__detail-item">
                <div className="content-line ">
                  <span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.06" cx="16" cy="16" r="16" fill="#F3CD29" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                        fill="#F3CD29"
                      />
                    </svg>
                    {t('job.complex_images')}
                  </span>
                  <span>
                    <small>{job_items.complex.length} {t('job.images')}</small>
                  </span>
                </div>

                <ImagePreviews
                  job_items={job_items.complex}
                  maxItems={2}
                  uploadedSize={job_items.complex.reduce((accumulator, currentValue) => accumulator + Number(currentValue.size), 0)}
                  editable={false} />
              </div>

              <div className="job__detail-item">
                <div className="content-line ">
                  <span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.06" cx="16" cy="16" r="16" fill="#F18F1C" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                        fill="#F18F1C"
                      />
                    </svg>
                    {t('job.super_complex_images')}
                  </span>
                  <span>
                    <small>{job_items.super_complex.length} {t('job.images')}</small>
                  </span>
                </div>

                <ImagePreviews
                  job_items={job_items.super_complex}
                  maxItems={2}
                  uploadedSize={job_items.super_complex.reduce((accumulator, currentValue) => accumulator + Number(currentValue.size), 0)}
                  editable={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(RequestedJobPaying);
