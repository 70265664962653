import React, { useState } from 'react';
import './UploadBox.scss';
import Preloader from '../Preloader/Preloader';
import { Trans, withTranslation } from 'react-i18next';
import ProgressBar from '../ProgressBar/ProgressBar';

const UploadBox = ({
                     isUploading = false,
                     setLoadedFiles = () => {
                     },
                     // setLoadedFilesToFormData = () => {
                     // },
                     single = false,
                     loadedFiles = {},
                     jobType = {},
                     id = 'file',
                     disabled = false,
                     uploadCount = 0,
                     setUploadCount = null,
                     t,
                   }) => {
  const [totalFiles, setTotalFiles] = useState(0);

  //Start file uploading
  const handleFileLoad = e => {
    const files = e.currentTarget.files || e.dataTransfer.files; // get file from input or drop zone
    const filesArray = Array.from(files);

    if (uploadCount !== totalFiles) {
      setTotalFiles(prevState => prevState + filesArray.length);
    } else {
      setUploadCount(0);
      setTotalFiles(filesArray.length);
    }

    filesArray.forEach(file => {
      setLoadedFiles(file, e)
    });

    e.target.value = '';
  };
  //End file uploading
  return (
    <div className={`upload-box ${isUploading && 'upload-box--uploading'}`}>
      <div className="upload-box__input">
        <input
          type="file"
          id={id}
          multiple={!single}
          onChange={handleFileLoad}
          disabled={disabled}
          accept="image/*"
        />
        <label
          htmlFor={id}
          onDragOver={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDrop={e => {
            e.preventDefault();
            e.stopPropagation();
            handleFileLoad(e);
          }}
        >
          {isUploading ? (
            <>
              <Preloader width="43px" height="43px"/>
              <span>
								Uploading in progress <br/> please don't close the tab
							</span>
            </>
          ) : (
            <>
              <svg
                width="23"
                height="43"
                viewBox="0 0 23 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22 14.8913L19.8049 17.726L18.7367 19.0979L3.40637 38.8792L1 42L2.78424 38.2927L9.82728 23.7603L11.4941 20.3111H1L10.3438 1H18.2202L11.4941 14.8913H22Z"
                  stroke="#F3CD29"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>
                <Trans i18nKey="modals.upload_or_drag" />
							</span>
            </>
          )}
          {single && loadedFiles.original && (
            <div className="preview preview--single-uploaded">
              <img src={loadedFiles.original} alt={loadedFiles.name}/>
            </div>
          )}
          {totalFiles > uploadCount ? <ProgressBar current={uploadCount} total={totalFiles} /> : ''}
        </label>
      </div>
    </div>
  );
};

export default withTranslation()(UploadBox);
